import React from 'react';

import CircleStar from '~images/artwork/circle-star.svg';
import CircleLightning from '~images/artwork/circle-lightning.svg';
import CircleSmile from '~images/artwork/circle-smile.svg';

import useStyles from './bar-tagline.styles';

const BarTagline = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.barTagline}>
      <div className={classes.tag}>
        <img src={CircleStar} alt="No Ads." className={classes.tagIcon} />
        No Ads.
      </div>
      <div className={classes.tag}>
        <img src={CircleLightning} alt="No Ads." className={classes.tagIcon} />
        No App.
      </div>
      <div className={classes.tag}>
        <img src={CircleSmile} alt="No Ads." className={classes.tagIcon} />
        No Stress.
      </div>
      <div className={classes.tag}>
        <img src={CircleStar} alt="No Ads." className={classes.tagIcon} />
        No Ads.
      </div>
      <div className={classes.tag}>
        <img src={CircleLightning} alt="No Ads." className={classes.tagIcon} />
        No App.
      </div>
      <div className={classes.tag}>
        <img src={CircleSmile} alt="No Ads." className={classes.tagIcon} />
        No Stress.
      </div>
    </div>
  )
}

export default BarTagline
