import {createUseStyles} from 'react-jss'

import {
  BREAKPOINTS
} from '~styles/vars.styles'

import {
  textTitle
} from '~styles/mixins.styles'

import dottedLines from '~images/background-dotted-lines.svg';

export default createUseStyles({
  testimonials: {
    background: `url(${dottedLines})`,
    backgroundPosition: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 60,
    [`@media (min-width: ${BREAKPOINTS.HERO_BIG}px)`]: {
      padding: 120
    }
  },

  icon: {
    marginBottom: 30
  },

  title: {
    ...textTitle
  },

  testimonialGroup: {
   display: 'flex',
   flexDirection: 'row',
   flexWrap: 'wrap',
   justifyContent: 'center'
  },

  testimonial: {
    flex: 1,
    margin: 15,
    maxWidth: 400,
    minWidth: 320
  }
})