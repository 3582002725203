import React from "react"

import SendNotifications from "~components/send-notifications/send-notifications.component"
import ReceiveNotifications from '~components/receive-notifications/receive-notifications.component'

import useStyles from './notifications.styles';

const Notifications = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.notifications}>
      <SendNotifications className={classes.sendNotifications} />
      <ReceiveNotifications className={classes.receiveNotifications} />
    </div>
  )
}

export default Notifications
