import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import Button from '~components/button/button.component';
import Megaphone from '~images/artwork/megaphone.svg';

import useStyles from './send-notifications.styles';

const SendNotifications = props => {
  const classes = useStyles(props);

  return (
    <div className={classNames(
      [classes.sendNotifications],
      {
        [props.className]: !!props.className
      }
    )}>
      <div className={classes.title}>
        <div className={classes.titleLine}>Send<img src={Megaphone} alt="Send messages" className={classes.titleIcon} /></div>
        <div>Messages</div>
      </div>
      <Button
        className={classes.button}
        to="/create-organization"
        iconRight="arrow-right"
        text="Create Account"
        theme="blue"
      />
      <div className={classes.alternate}>Already have an account? —&nbsp;
      <Link
        to="/admin-login"
        className={classes.alternateLink}
      >
          Log in here
        </Link>
      </div>
    </div>
  )
}

export default SendNotifications
