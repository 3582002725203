import {createUseStyles} from 'react-jss'
import color from 'color';

import {
  TRANSITION as TRANSITION_COMMON
} from '@theme/vars';

import {
  COLORS
} from '~styles/vars.styles'

import {
  buttonSmall,
  buttonMedium,
  tooltip,
} from '~styles/mixins.styles'

const boxShadow = props => {
  if (props.shadow) {
    switch (props.theme) {
      case 'accent':
        return `0 15px 35px ${color(COLORS.ACCENT).alpha(0.25).string()}`
      case 'input':
        return `inset 0 3px 7px ${color(COLORS.BLUE_02).alpha(0.25).string()}`
      case 'blue':
          default:
        return `0 15px 35px ${color(COLORS.BLUE_02).alpha(0.25).string()}`
    }
  } else {
    return null
  }
}

const pillBorder = props => {
  switch (props.theme) {
    case 'accent':
      return `1px solid ${color(COLORS.ACCENT).darken(0.5).alpha(0.25).string()}`
    case 'input':
      return `1px solid ${color(COLORS.LIGHT_03).darken(0.05).alpha(0.25).string()}`
    case 'blue':
        default:
      return `1px solid ${color(COLORS.BLUE_02).darken(0.25).alpha(0.25).string()}`
  }
}

const size = props => {
  switch (props.size) {
    case 'small':
      return buttonSmall
    case 'medium':
      default:
        return buttonMedium
  }
}


export default createUseStyles({
  button: props => ({
    ...size(props),
    border: 'none',
    display: 'inline-block',
    fontWeight: 600,
    lineHeight: 1.25,
    boxShadow: props => boxShadow(props)
  }),

  buttonInner: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },

  iconRight: {
    marginLeft: 10
  },

  themeBlue: {
    background: COLORS.BLUE_02,
    color: COLORS.LIGHT_01,
    '&:hover': {
      background: color(COLORS.BLUE_02).darken(0.1).string(),
      transition: `background ${TRANSITION_COMMON.DURATION_SM}ms ease-in-out`
    },
    '& $iconRight': {
      color: COLORS.ACCENT
    }
  },

  themeAccent: {
    background: COLORS.ACCENT,
    color: COLORS.PURPLE_01,
    '&:hover': {
      background: color(COLORS.ACCENT).darken(0.1).string(),
      transition: `background ${TRANSITION_COMMON.DURATION_SM}ms ease-in-out`
    },
    '& $iconRight': {
      color: COLORS.PURPLE_01
    }
  },

  themeInput: {
    background: COLORS.LIGHT_02,
    color: COLORS.LIGHT_03,
    fontStyle: 'italic',
    '&:hover': {
      background: color(COLORS.LIGHT_03).darken(0.1).string(),
      transition: `background ${TRANSITION_COMMON.DURATION_SM}ms ease-in-out`
    },
    '& $iconRight': {
      color: COLORS.BLUE_02
    }
  },

  pillLeft: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },

  pillCenter: {
    borderRadius: 0,
    borderLeft: props => pillBorder(props)
  },

  pillRight: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: props => pillBorder(props)
  },

  tooltip: {
    ...tooltip
  }
})