import React from 'react';

import BubbleDialogue from '~images/artwork/bubble-dialogue.svg';
import Testomonial from '~components/testimonial/testimonial.component';

import customerHeadshot1 from '~images/photos/customer-headshot-1@2x.jpg';
import customerHeadshot2 from '~images/photos/customer-headshot-2@2x.jpg';
import customerHeadshot3 from '~images/photos/customer-headshot-3@2x.jpg';

import useStyles from './testimonials.styles';

const Testimonials = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.testimonials}>
      <img src={BubbleDialogue} alt="Dialogue bubble" className={classes.icon} />
      <div className={classes.title}>A word from our customers.</div>
      <div className={classes.testimonialGroup}>
        <Testomonial
          className={classes.testimonial}
          customerHeadshot={customerHeadshot1}
          customerName="Corrine D."
          customerLocation="Baltimore, MD"
          customerTestimonial="RainedOut is a quick and easy way for us to let people know we're not racing tonight.  It's very user-friendly.  It takes thirty seconds to use."
        />
        <Testomonial
          className={classes.testimonial}
          customerHeadshot={customerHeadshot2}
          customerName="Martina B."
          customerLocation="Los Angeles, CA"
          customerTestimonial="RainedOut.com has made it much easier to communicate to others about cancellations.  It helps make my job easier."
        />
        <Testomonial
          className={classes.testimonial}
          customerHeadshot={customerHeadshot3}
          customerName="Wulf K."
          customerLocation="New York, NY"
          customerTestimonial="RainedOut makes my life easier and they don't sell my data.  I like that!"
        />
      </div>
    </div>
  )
}

export default Testimonials
