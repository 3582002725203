import {createUseStyles} from 'react-jss'

import {
  BREAKPOINTS,
  COLORS
} from '~styles/vars.styles'

export default createUseStyles({
  bar: {
    alignItems: 'center',
    display: 'flex',
    height: 100,
    justifyContent: 'center',
    width: '100%',

    [`@media (min-width: ${BREAKPOINTS.BAR_BIG}px)`]: {
      height: 120,
    }
  },

  barTagline: {
    alignItems: 'center',
    backgroundColor: COLORS.LIGHT_02,
    display: 'flex',
    extend: 'bar',
    justifyContent: 'center',
    overflow: 'hidden',
  },

  tag: {
    alignItems: 'center',
    color: COLORS.BLUE_MUTED_02,
    display: 'flex',
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: '-0.05em',
    margin: [0, 30],
    opacity: 0.25,
    whiteSpace: 'nowrap',

    [`@media (min-width: ${BREAKPOINTS.BAR_BIG}px)`]: {
      fontSize: 24,
      margin: [0, 60]
    }
  },

  tagIcon: {
    marginRight: 20
  }
})