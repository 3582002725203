import React from 'react';
import classNames from 'classnames';

import Button from '~components/button/button.component';
import BubbleMessage from '~images/artwork/bubble-message.svg';

import useStyles from './receive-notifications.styles';

const ReceiveNotifications = props => {
  const classes = useStyles(props);

  return (
    <div className={classNames(
      [classes.receiveNotifications],
      {
        [props.className]: !!props.className
      }
    )}>
      <div className={classes.title}>
        <div className={classes.titleLine}>Receive<img src={BubbleMessage} alt="Receive messages" className={classes.titleIcon} /></div>
        <div>Messages</div>
      </div>
      <Button
          className={classes.button}
          to="/team"
          iconRight="search"
          shadow={true}
          text="Search Orgs…"
          theme="input"
        />
        <div className={classes.alternate}>
          Start here to receive Email or Text Messaging
        </div>
    </div>
  )
}

export default ReceiveNotifications
