import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useStyles from './testimonial.styles';

const Testimonial = props => {
  const classes = useStyles(props);

  return (
    <div className={classNames(
      [classes.testimonial],
      {
        [props.className]: !!props.className
      }
    )}>
      <div className={classes.headshot} />
      <div className={classes.title}>{props.customerName}</div>
      <div className={classes.subtitle}>{props.customerLocation}</div>
      <div className={classes.body}>{props.customerTestimonial}</div>
    </div>
  )
}

Testimonial.propTypes = {
  customerHeadshot: PropTypes.node,
  customerName: PropTypes.string,
  customerLocation: PropTypes.string,
  customerTestimonial: PropTypes.string
}

Testimonial.defaultProps = {
  customerHeadshot: null,
  customerName: 'Full Name',
  customerLocation: 'Palo Alto, CA',
  customerTestimonial: 'Sunt autem quibusdam et expedita distinctio nam libero tempore, cum teneam sententiam, quid iudicat, quo voluptas assumenda est,.'
}

export default Testimonial
