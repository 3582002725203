import {createUseStyles} from 'react-jss'

export default createUseStyles({
  notifications: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  sendNotifications: {
    flex: 1
  },

  receiveNotifications: {
    flex: 1
  }
})