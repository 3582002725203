import {createUseStyles} from 'react-jss';
import color from 'color';

import {
  BREAKPOINTS,
  COLORS,
  ELEMENTS
} from '~styles/vars.styles'

import lineartTexture from '~images/lineart-texture-dark.svg';

export default createUseStyles({
  '@keyframes enterActive': {
    from: {
      opacity: 0,
      transform: 'translate3d(0, 50px, 0) scale(0.5)'
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0, 0, 0) scale(1)'
    }
  },

  '@keyframes exitActive': {
    from: {
      opacity: 1,
      transform: 'scale(1)'
    },
    to: {
      opacity: 0,
      transform: 'scale(0)'
    }
  },

  hero: {
    backgroundImage: `url(${lineartTexture}), radial-gradient(circle at center left, #F7F8FA, #E1E9FF)`,
    backgroundRepeat: 'repeat, no-repeat',
    backgroundSize: '280px, 100%',
    display: 'flex',
    flexDirection: 'column',
    height: 620,
    overflow: 'hidden',
    padding: [ELEMENTS.HEADER.HEIGHT + 30, 50],
    width: '100%',

    [`@media (min-width: ${BREAKPOINTS.HERO_PHONE_SIDE}px)`]: {
      flexDirection: 'row',
      overflow: 'visible'
    },

    [`@media (min-width: ${BREAKPOINTS.HERO_BIG}px)`]: {
      padding: [ELEMENTS.HEADER.HEIGHT + 50, 0, ELEMENTS.HEADER.HEIGHT + 50, ELEMENTS.HEADER.HEIGHT + 50],
    }
  },

  callToAction: {
    // Honor the size of our copy and respect the line breaks.
    flex: 'none',

    position: 'relative',

    // Vertically align the CTA on smaller screens
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',

    [`@media (min-width: ${BREAKPOINTS.HERO_BIG}px)`]: {
      // Allow CTA to reside at the top on larger screens
      display: 'block'
    }
  },

  phoneAndMessages: {
    flex: 1,
    order: 2,
    position: 'relative',
  },

  phone: {
    position: 'absolute',
    top: '20%',
    left: '25%',
    width: 320,
    transform: 'rotateZ(-15deg)',

    [`@media (min-width: ${BREAKPOINTS.HERO_PHONE_SIDE}px)`]: {
      top: '-5%'
    }
  },

  messages: {
    position: 'absolute',
    top: '15%',

    [`@media (min-width: ${BREAKPOINTS.HERO_PHONE_SIDE}px)`]: {
      top: '20%'
    }
  },

  message: {
    '--odd-message-color': color(COLORS.BLUE_02).desaturate(0.75).string(),
    '--even-message-color': color(COLORS.BLUE_01).desaturate(0.75).string(),
    borderRadius: 11,
    fontSize: 14,
    letterSpacing: '-0.045em',
    lineHeight: 1.5,
    padding: 20,

    // Position the messages absolutely so the next set of messages animating
    // in do not displace the positioning of the previously animated set of messages.
    position: 'absolute',
    
    width: 300,

    // Stagger animation in so top message comes in last and add alternating background
    // color and margin offset.
    '&:nth-child(1)': {
      top: 0,
      animationDelay: ELEMENTS.HERO.MESSAGE.ANIMATION_STAGGER_INTERVAL * 3 + 'ms',
      background: 'var(--odd-message-color)',
      marginLeft: 15
    },
    '&:nth-child(2)': {
      top: 100,
      animationDelay: ELEMENTS.HERO.MESSAGE.ANIMATION_STAGGER_INTERVAL * 2 + 'ms',
      background: 'var(--even-message-color)',
      marginLeft: -15
    },
    '&:nth-child(3)': {
      top: 200,
      animationDelay: ELEMENTS.HERO.MESSAGE.ANIMATION_STAGGER_INTERVAL + 'ms',
      background: 'var(--odd-message-color)',
      marginLeft: 15
    },

    // Animation out needs to retain its positioning.  The next set of messages has
    // already been added to the DOM at this point and is hidden by way of our
    // animationDelay.
    '&:nth-child(4)': {
      top: 0,
      animationDelay: 0,
      background: 'var(--odd-message-color)',
      marginLeft: 15
    },
    '&:nth-child(5)': {
      top: 100,
      animationDelay: 0,
      background: 'var(--even-message-color)',
      marginLeft: -15
    },
    '&:nth-child(6)': {
      top: 200,
      animationDelay: 0,
      background: 'var(--odd-message-color)',
      marginLeft: 15
    },

    [`@media (min-width: ${BREAKPOINTS.HERO_PHONE_SIDE}px)`]: {
      // Messages are desaturated by default as they reside just under our CTA button.
      // Once the screen grows, re-saturate the faux messages.
      '--odd-message-color': COLORS.GREEN_02,
      '--even-message-color': COLORS.GREEN_01
    }
  },

/**
 * Individual message transitions are handled with a transition group.
 */
  messageEnterActive: {
    animationFillMode: 'both',
    animationName: '$enterActive',
    animationTimingFunction: 'ease-in-out',
    animationDuration: ELEMENTS.HERO.MESSAGE.ANIMATION_DURATION + 'ms',
  },
  messageExitActive: {
    animationFillMode: 'both',
    animationName: '$exitActive',
    animationTimingFunction: 'ease-in-out',
    animationDuration: ELEMENTS.HERO.MESSAGE.ANIMATION_DURATION + 'ms'
  },

  messageTitle: {
    color: COLORS.LIGHT_01,
    fontWeight: 600,
  },

  messageTime: {
    float: 'right'
  },

  messageBody: {
    color: COLORS.LIGHT_02,
    fontWeight: 400,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },

  title: {
    color: COLORS.PURPLE_01,
    fontSize: 40,
    fontWeight: 600,
    letterSpacing: '-0.045em',
    lineHeight: 1.0461,
    marginBottom: 30,

    [`@media (min-width: ${BREAKPOINTS.HERO_BIG}px)`]: {
      fontSize: 65,
      marginBottom: 40
    }
  },

  highlight: {
    color: COLORS.BLUE_01,

    [`@media (min-width: ${BREAKPOINTS.HERO_BIG}px)`]: {
      position: 'absolute',
      paddingLeft: 20
    }
  },

  subtitle: {
    maxWidth: 320,
    marginBottom: 30,
    lineHeight: 1.5,
    letterSpacing:'-0.03777em',
    fontWeight: 400,
    fontSize: 16,
    color: COLORS.BLUE_MUTED_02,

    [`@media (min-width: ${BREAKPOINTS.HERO_BIG}px)`]: {
      fontSize: 18,
      marginBottom: 40
    }
  },

  // Stack buttons vertically on smaller screens and allow them
  // to sit side-by-side on bigger screens.
  buttonGroup: {
    '& $button': {
      display: 'block',
      [`@media (min-width: ${BREAKPOINTS.HERO_BUTTONS_BIG}px)`]: {
        display: 'inline-block'
      }
    },

    '& $button:last-child': {
      marginTop: 15,

      [`@media (min-width: ${BREAKPOINTS.HERO_BUTTONS_BIG}px)`]: {
        margin: [0, 0, 0, 15]
      },
    }
  },

  button: {
    fontSize: 14,
    [`@media (min-width: ${BREAKPOINTS.HERO_BUTTONS_MEDIUM}px)`]: {
      fontSize: 20,
    },
    [`@media (min-width: ${BREAKPOINTS.HERO_BIG}px)`]: {
      fontSize: 28
    }
  }
})