import {createUseStyles} from 'react-jss'

import {
  BREAKPOINTS,
  COLORS
} from '~styles/vars.styles'

export default createUseStyles({
  testimonial: {
    background: COLORS.LIGHT_02,
    borderRadius: 10,
    padding: 20,
    position: 'relative'
  },

  headshot: {
    background: COLORS.LIGHT_03,
    backgroundImage: props => `url(${props.customerHeadshot})`,
    backgroundSize: 'cover',
    border: [5, COLORS.LIGHT_01, 'solid'],
    borderRadius: '50%',
    height: 85,
    width: 85,
    position: 'absolute',
    left: -20,
    top: -20
  },

  icon: {
    marginBottom: 30
  },

  title: {
    color: COLORS.BLUE_02,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: '-0.045em',
    lineHeight: '1.25em',
    paddingLeft: 50
  },

  subtitle: {
    color: COLORS.BLUE_MUTED_02,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: '-0.045em',
    lineHeight: '1.5em',
    paddingLeft: 50
  },

  body: {
    color: COLORS.BLUE_MUTED_02,
    margin: 10,
    fontSize: 16,
    fontWeight: 400,
    letterSpacing:'-0.03777em',
    [`@media (min-width: ${BREAKPOINTS.MENU_BIG}px)`]: {
      fontSize: 18
    }
  }
})