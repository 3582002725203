import React from "react"

// import BarLogos from '~components/bar-logos/bar-logos.component'
import BarTagline from '~components/bar-tagline/bar-tagline.component'
import Layout from "~components/layout/layout.component"
import SEO from "~components/seo/seo.component"
import Notifications from "~components/notifications/notifications.component"
import Hero from '~components/hero/hero.component';
import Testimonials from '~components/testimonials/testimonials.component';
import Features from '~components/features/features.component';

import { LAYOUT_NAME_FULL } from '~constants';

const IndexPage = () => (
  <Layout layout={LAYOUT_NAME_FULL}>
    <SEO title="Home" />
    <Hero />
    <BarTagline />
    <Notifications />
    <Testimonials />
    <BarTagline />
    <Features />
  </Layout>
)

export default IndexPage
