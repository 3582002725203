import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import ReactTooltip from 'react-tooltip'

import {
  ICON_NAMES,
  KEY_CODES
} from '~constants';

import Icon from '~components/icon/icon.component';

import useStyles from './button.styles';

const Button = props => {
  const classes = useStyles(props);

  const getClasses = () => classNames(
    [classes.button],
    {
      [props.className]: !!props.className,
      [classes.themeAccent]: props.theme === 'accent',
      [classes.themeBlue]: props.theme === 'blue',
      [classes.themeInput]: props.theme === 'input',
      [classes.pillLeft]: props.location === 'left',
      [classes.pillCenter]: props.location === 'center',
      [classes.pillRight]: props.location === 'right'
    }
  );

  const renderChildren = () => (
    <span className={classes.buttonInner}>
      {props.text}
      {props.iconRight ? <Icon name={props.iconRight} className={classes.iconRight} /> : null}
    </span>
  )

  return (
    // Click handler
    props.onClick ? 
      <>
        <div
          role="button"
          className={getClasses()}
          onClick={props.onClick}
          onKeyDown={event => {
            if (event.keyCode === KEY_CODES.ENTER) {
              props.onClick(event);
            }}
          }
          tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
          data-tip={props.dataTip}   // `react-tooltip` doesn't camelCase props.
          data-for={props.dataTipId} // `react-tooltip` doesn't camelCase props.
        >
          {renderChildren()}
        </div>
        {props.dataTipId && <ReactTooltip id={props.dataTipId} className={classes.tooltip} effect="solid" />}
      </> : 
    // Internal Gatsby link
    props.to ? 
      <Link to={props.to} className={getClasses()}>
        {renderChildren()}
      </Link> : 
    // Use inside of a <form> as the default submit action, icons not supported.
    props.inputSubmit ?
      <input
        type="submit"
        name="submit"
        value={props.text}
        className={getClasses()}
        tabIndex={0}
      /> :
    // Outbound links
      <OutboundLink
        href={props.href}
        className={getClasses()}
      >
        {renderChildren()}
      </OutboundLink>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  iconRight: PropTypes.oneOf(ICON_NAMES),
  location: PropTypes.oneOf(['left', 'center', 'right']),
  shadow: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  text: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['accent', 'blue', 'input']),
  to: PropTypes.string
}

Button.defaultProps = {
  className: '',
  href: '',
  iconRight: null,
  location: null,
  shadow: false,
  size: 'medium',
  theme: 'blue',
  to: ''
}

export default Button
