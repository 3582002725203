import React, { useState } from 'react';

import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

import Button from '~components/button/button.component';

import phone from '~images/phone.svg';

import useInterval from '~common/use-interval.hook';
import useStyles from './hero.styles';

import {
  ELEMENTS
} from '~styles/vars.styles'

const HIGHLIGHT_WORDS = [
  'Team',
  'Church',
  'Org',
  'Club'
];

const HIGHLIGHT_SMS_MESSAGES = [
  [
    {
      title: 'U12 G Soccer United',
      body: 'Youth winter league games ON. Recommend running shoes/sneakers for players as option for footwear.',
      time: '2m ago'
    },
    {
      title: 'Park & Rec U9 B Baseball',
      body: 'We will play at the Orioles home field. Wear white, be at the field by 5pm.',
      time: '15m ago'
    },
    {
      title: 'Girls Travel Soccer',
      body: 'We ARE having practice today as the sun is finally out.',
      time: '3h ago'
    }
  ],
  [
    {
      title: 'Youth Ministry',
      body: 'Bring your baskets, boots and ponchos the Annual Easter Egg Hunt is ON! See everyone there.',
      time: '2m ago'
    },
    {
      title: 'Choir Practice',
      body: 'Great job for the past 3 weeks. Lets keep it up.',
      time: '15m ago'
    },
    {
      title: 'United Methodist Soup Kitchen',
      body: 'If you have not already done so, please remember to turn in your fundraiser money today!',
      time: '3h ago'
    }
  ],
  [
    {
      title: 'Park Elementary School',
      body: 'All staff please make sure to fill out a separate timesheet for the all staff meetings.',
      time: '2m ago'
    },
    {
      title: 'Volunteer Dog Trainers',
      body: 'No staff meeting today',
      time: '15m ago'
    },
    {
      title: 'Velocity Raceway',
      body: 'Come cool off tonight at our family night BBQ/cruise. All you can eat food and drinks included! Open 6pm',
      time: '3h ago'
    }
  ],
  [
    {
      title: 'Spanish Club',
      body: 'Hey everyone, just a reminder unit test tonight - ch1-4 ',
      time: '2m ago'
    },
    {
      title: 'Gaelic Dance',
      body: 'The Walk-In Program will close at 6pm today.  The Christmas Dinner is on!',
      time: '15m ago'
    },
    {
      title: 'Axe-throwing',
      body: ' T SHIRTS ARE IN!!!!! BRING $13 to the meeting if you ordered one! THEY LOOK AWESOME!!!!! ',
      time: '3h ago'
    }
  ]
];

const HIGHLIGHT_CHANGE_INTERVAL = 5000;

const Hero = props => {
  const classes = useStyles(props);
  
  const [highlightWordIndex, setHightlightWordIndex] = useState(0)
  
  useInterval(() => {
    if (highlightWordIndex + 1 < HIGHLIGHT_WORDS.length) {
      setHightlightWordIndex(highlightWordIndex + 1);
    } else {
      setHightlightWordIndex(0)
    }
  }, HIGHLIGHT_CHANGE_INTERVAL);

  return (
    <div className={classes.hero}>

      <div className={classes.phoneAndMessages}>
        <div className={classes.phone}>
          <img src={phone} alt="RainedOut on a phone." />
          <TransitionGroup className={classes.messages}>
            {HIGHLIGHT_SMS_MESSAGES[highlightWordIndex].map((message, idx) => (
              <CSSTransition
                key={highlightWordIndex + '-' + idx}
                appear={true}
                timeout={{
                   // Add 600ms as that's the delay of our last message
                  appear: (ELEMENTS.HERO.MESSAGE.ANIMATION_DURATION * 2) + (ELEMENTS.HERO.MESSAGE.ANIMATION_STAGGER_INTERVAL * 3),
                  enter: (ELEMENTS.HERO.MESSAGE.ANIMATION_DURATION * 2) + (ELEMENTS.HERO.MESSAGE.ANIMATION_STAGGER_INTERVAL * 3), // Add 600ms as that's the delay of our last message
                  exit: ELEMENTS.HERO.MESSAGE.ANIMATION_DURATION * 2,
                 }}
                classNames={{
                  appear: classes.messageEnterActive,
                  enter: classes.messageEnterActive,
                  exit: classes.messageExitActive
                }}
              >
                <div className={classes.message}>
                  <div className={classes.messageTitle}>
                    {message.title}
                    <span className={classes.messageTime}>{message.time}</span>
                  </div>
                  <div className={classes.messageBody}>
                    {message.body}
                  </div>
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
      </div>

      <div className={classes.callToAction}>
        <div className={classes.title}>
          Connecting You <br/>To Your <span className={classes.highlight}>{HIGHLIGHT_WORDS[highlightWordIndex]}.</span>
        </div>
        <div className={classes.subtitle}>
        Fast, easy messaging so you stay connected  with no ads, no app needed, and no stress.
        </div>
        <div className={classes.buttonGroup}>
          <Button
            className={classes.button}
            to="/create-organization"
            iconRight="arrow-right"
            shadow={true}
            text="Send Messages"
            theme="blue"
          />
          <Button
            className={classes.button}
            to="/team"
            iconRight="arrow-right"
            shadow={true}
            text="Receive Messages"
            theme="accent"
          />
        </div>
      </div>

    </div>
  )
}

Hero.propTypes = {}

Hero.defaultProps = {}

export default Hero
