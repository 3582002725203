import {createUseStyles} from 'react-jss'

import {
  BREAKPOINTS,
  COLORS
} from '~styles/vars.styles'

import backgroundImage from '~images/photos/blue-receive.jpg';

export default createUseStyles({
  receiveNotifications: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: COLORS.BLUE_02,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    padding: 60,
    [`@media (min-width: ${BREAKPOINTS.HERO_BIG}px)`]: {
      padding: 120
    }
  },

  title: {
    color: COLORS.LIGHT_01,
    fontSize: 45,
    letterSpacing: '-0.045em',
    lineHeight: '1.25em',
    fontWeight: 600,
    marginBottom: 30,
    [`@media (min-width: ${BREAKPOINTS.HERO_BIG}px)`]: {
      padding: 60
    }
  },

  titleLine: {
    display: 'flex',
    alignItems: 'center'
  },

  titleIcon: {
    marginLeft: 27
  },

  button: {
    fontSize: 20,
    [`@media (min-width: ${BREAKPOINTS.HERO_BIG}px)`]: {
      fontSize: 28
    }
  },

  alternate: {
    color: COLORS.ACCENT,
    fontWeight: 600,
    marginTop: 30
  },

  alternateLink: {
    color: COLORS.ACCENT,
    textDecoration: 'underline'
  }
})